<template>
  <div class="superset_page">
    <div class="heading dark-text">
      <h1>{{ $t("dashboard") }}</h1>
    </div>
    <div v-if="isSupersetDashboardsLoading" class="loading dark-text">
      <i class="fa fa-fw fa-spinner fa-spin"></i>{{ $t("loading_dashboards") }}
    </div>
    <v-card class="flex-column dark-text superset-container" v-else>
      <div v-for="dashboard in supersetDashboards" :key="dashboard.id">
        <button
          class="dashboard-btn dark-text"
          :class="{
            'dashboard-btn-selected': dashboard === selectedDashboard
          }"
          @click="handleDashboardSelect(dashboard)"
          :data-testid="dashboard.id"
        >
          {{ dashboard.dashboardName }}
        </button>
      </div>
    </v-card>
    <div id="superset-embed-container"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { embedDashboard } from '@everwell/embed-superset'
import { defaultToast, ToastType } from '../../../../utils/toastUtils'

export default {
  name: 'supersetDashboard',
  metaInfo: {
    title: 'Dashboards'
  },
  data: self => ({
    selectedDashboard: null
  }),

  async mounted () {
    await this.fetchSupersetDashbaords()
    this.handleDashboardSelect(this.supersetDashboards[0])
  },

  computed: {
    ...mapState('SupersetDashboard', [
      'supersetDashboards',
      'isSupersetDashboardsLoading'
    ])
  },

  methods: {
    ...mapActions('SupersetDashboard', [
      'fetchSupersetDashbaords',
      'fetchSupersetGuestToken'
    ]),

    async handleDashboardSelect (dashboard) {
      this.selectedDashboard = dashboard
      this.embedSuperset()
    },

    async embedSuperset () {
      try {
        await embedDashboard({
          id: this.selectedDashboard.embeddingUUID,
          supersetDomain: process.env.VUE_APP_SUPERSET_URL,
          mountPoint: document.getElementById('superset-embed-container'),
          fetchGuestToken: async () =>
            await this.fetchSupersetGuestToken(
              this.selectedDashboard.embeddingUUID
            ),
          dashboardUiConfig: {
            hideTitle: true
          }
        })
      } catch (err) {
        defaultToast(
          ToastType.Error,
          `Unable to load dashboard ${this.selectedDashboard.dashboardName}`
        )
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {

  background-color: var(--background-color-primary-light-dark);
  box-shadow: 0px 2px 2px var(--very-light-shadow);
}
.heading {
  padding-bottom: 12px;
}

.flex-column {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.dashboard-btn {
  padding: 20px;
  padding-bottom: 18px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-shadow: 1px 0px var(--very-light-shadow);
}

.dashboard-btn-selected {
  background-color: rgba($primary, 0.05);
  border-bottom: 2px solid var(--primary-color-text);
  color: var(--primary-color-text);
}

[data-theme="dark-theme"] {
  .dashboard-btn-selected {
    background-color: var(--highlight-color);
  }
}

#superset-embed-container {
  margin-top: 9px;
  flex-grow: 1;
}

#superset-embed-container ::v-deep iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.superset_page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
